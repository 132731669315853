/* eslint-disable no-param-reassign */
import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import {get, last, first} from 'lodash';
import * as Sentry from '@sentry/browser';
import {toast} from '../../utils/toast';
import BillingAPI from '../../services/api/billing';
import MemberAPI from '../../services/api/members';
import UserAPI from '../../services/api/user';
import {fetchUserProfile} from '../settings';
import {
  unauthorizedStatusCode,
  unprocessableEntityStatusCode,
  serverErrorStatusCode
} from '../../constants/responseCodes';
import {triggerGA4Event} from '../../components/Tracking/UserTrackingGA4';

export const setPlans = createAction('BILLING/SET_PLANS');
export const setPlan = createAction('BILLING/SET_PLAN');
export const setFeatures = createAction('BILLING/SET_FEATURES');
export const setUsers = createAction('BILLING/SET_USERS');
export const setIsCanceling = createAction('BILLING/SET_IS_CANCELING');
export const setCurrentSubscription = createAction('BILLING/SET_CURRENT_SUBSCRIPTION');
export const setInvoices = createAction('BILLING/SET_INVOICES');
export const addInvoices = createAction('BILLING/ADD_INVOICES');
export const toggleFetching = createAction('BILLING/TOGGLE_FETCHING');
export const setInvitationCreated = createAction('BILLING/INVITATION_CREATED');
export const setInvitations = createAction('BILLING/INVITATIONS');
export const setTeamMembers = createAction('BILLING/TEAM_MEMBERS');
export const setManageRights = createAction('BILLING/MANAGE_MEMBERS');
export const setBillingDetails = createAction('BILLING/SET_BILLING_DETAILS');
export const initialState = {
  plans: {
    data: [],
    isLoading: true
  },
  features: {
    data: [],
    isLoading: true
  },
  plan: {
    data: null,
    isLoading: true
  },
  invoices: {
    data: [],
    meta: {},
    isLoading: true
  },
  invitations: [],
  canManage: false,
  currentSubscription: null,
  isCanceling: false,
  users: {
    data: null,
    isLoading: true
  }
};

const handleRedirect = (redirect) => {
  window.location.href = `${process.env.REACT_APP_HOST}${redirect.slice(
    redirect.indexOf('/stripe')
  )}`;
};

export default createReducer(initialState, {
  [setPlans]: (state, action) => {
    state.plans.data = action.payload;
  },
  [setPlan]: (state, action) => {
    state.plan.data = action.payload;
  },
  [setFeatures]: (state, action) => {
    state.features.data = action.payload;
  },
  [setUsers]: (state, action) => {
    state.users.data = action.payload;
  },
  [setIsCanceling]: (state, action) => {
    state.isCanceling = action.payload;
  },
  [setCurrentSubscription]: (state, action) => {
    state.currentSubscription = action.payload;
  },
  [setInvoices]: (state, action) => {
    state.invoices.data = action.payload.data;
    state.invoices.meta = action.payload.meta;
  },
  [addInvoices]: (state, action) => {
    state.invoices.data = [...state.invoices.data, ...action.payload.data];
    state.invoices.meta = action.payload.meta;
  },
  [toggleFetching]: (state, action) => {
    state[action.payload.key].isLoading = action.payload.toggle;
  },
  [setInvitations]: (state, action) => {
    state.invitations = action.payload;
  },
  [setTeamMembers]: (state, action) => {
    state.teamMembers = action.payload;
  },
  [setManageRights]: (state, action) => {
    state.canManage = action.payload;
  },
  [setBillingDetails]: (state, action) => {
    return {
      ...state,
      currentSubscription: {
        ...state.currentSubscription,
        billing_details: action.payload
      }
    };
  }
});

/* ACTION CREATORS */
export const fetchPlans = () => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'plans', toggle: true}));

    const {
      data: {data}
    } = await BillingAPI.getPlans();

    dispatch(setPlans(data));

    return data;
  } catch (err) {
    if (
      ![unauthorizedStatusCode, unprocessableEntityStatusCode, serverErrorStatusCode].includes(
        err.response.status
      )
    ) {
      toast.error('There was an error trying to fetch billing plans');
    }
  } finally {
    dispatch(toggleFetching({key: 'plans', toggle: false}));
  }
};

export const unloadPlan = () => (dispatch) => {
  dispatch(toggleFetching({key: 'plans', toggle: true}));
  dispatch(setPlan(null));
};

const HTTP_REDIRECT_CODE = 302;

export const subscribe = ({
  plan,
  interval,
  stripeToken,
  couponId,
  couponCode,
  companyName,
  taxType,
  taxID,
  address,
}) => async (dispatch) => {
  const {
    planId, slug, numSeats
  } = plan;

  return BillingAPI.subscribe({
    planId,
    stripeToken,
    couponId,
    couponCode,
    companyName,
    taxType,
    taxID,
    address,
    numSeats
  })
    .then((response) => {
      toast.success(response.data.message);
      triggerGA4Event({name: `AppSubscription_${interval}_${slug}`, category: 'billing', action: 'created'});

      dispatch(fetchCurrentSubscription());
      dispatch(fetchPlans());
      dispatch(fetchUserProfile());

      return true;
    })
    .catch((error) => {
      if (error.response.status === HTTP_REDIRECT_CODE) {
        const redirect = error.response.data.data.route;

        handleRedirect(redirect);

        toast.success(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }

      return false;
    });
};

export const upgrade = (param) => async (dispatch) => {
  return BillingAPI.upgrade(param)
    .then((response) => {
      toast.success(response.data.message);
      dispatch(fetchCurrentSubscription());
      dispatch(fetchPlans());
      dispatch(fetchUserProfile());
    })
    .catch((error) => {
      if (error.response.status === HTTP_REDIRECT_CODE) {
        const redirect = error.response.data.data.route;

        handleRedirect(redirect);

        toast.success(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    });
};

export const checkout = ({
  planId, numSeats, promoCode, currency
}) => async () => {
  return BillingAPI.checkout({
    planId, numSeats, promoCode, currency
  })
    .then((response) => {
      if (response?.data && response?.data?.url) {
      // Redirect the user to the Stripe checkout page
        window.location.href = response.data.url;
      } else {
        toast.error('There was an error trying to checkout. Please try again later or contact support.');
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
      toast.error('There was an error trying to checkout. Please try again later or contact support.');
    });
};

export const swapSubscription = () => async (dispatch) => {
  return BillingAPI.swap()
    .then((response) => {
      dispatch(fetchCurrentSubscription());
      dispatch(fetchInvoices());
      toast.success(response.data.message);
      dispatch(fetchPlans());
      dispatch(fetchUserProfile());
    })
    .catch((error) => {
      if (error.response.status === HTTP_REDIRECT_CODE) {
        const redirect = error.response.data.data.route;

        handleRedirect(redirect);

        toast.success(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    });
};

export const cardUpdate = ({token}) => async (dispatch) => {
  return BillingAPI.changeCard({token})
    .then(async (response) => {
      toast.success(response.data.message);

      /* Fetch user profile data */
      const {
        data: {data}
      } = await UserAPI.getProfile();

      /* Update billing data */
      const newBillingData = data?.subscription?.billing_details;

      if (newBillingData) {
        dispatch(setBillingDetails(newBillingData));
      }
    })
    .catch((error) => {
      if (error.response.status === HTTP_REDIRECT_CODE) {
        const redirect = error.response.data.data.route;

        handleRedirect(redirect);

        toast.success(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    });
};

export const billingUpdate = (newData) => async (dispatch) => {
  return BillingAPI.updateBilling(newData)
    .then(async (response) => {
      toast.success(response.data.message);

      /* Fetch user profile data */
      const {
        data: {data}
      } = await UserAPI.getProfile();

      /* Update billing data */
      const newBillingData = data?.subscription?.billing_details;

      if (newBillingData) {
        dispatch(setBillingDetails(newBillingData));
      }
    })
    .catch((error) => {
      if (error.response.status === HTTP_REDIRECT_CODE) {
        const redirect = error.response.data.data.route;

        handleRedirect(redirect);

        toast.success(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    });
};

export const cancelSubscription = ({reason}) => async (dispatch) => {
  dispatch(setIsCanceling(true));

  const data = await BillingAPI.cancelSubscription({reason})
    .then((response) => {
      dispatch(fetchCurrentSubscription());
      toast.success(response.data.message);
    })
    .catch((error) => toast.error(error.response?.data?.message ||
      'There was an error trying to cancel subscription. Please contact support.'))
    .finally(() => dispatch(setIsCanceling(false)));

  return data;
};

export const fetchPlan = (planId) => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'plan', toggle: true}));

    const {data} = await BillingAPI.getPlan(planId);

    dispatch(setPlan(data));

    return data;
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  } finally {
    dispatch(toggleFetching({key: 'plan', toggle: false}));
  }
};

export const fetchCurrentSubscription = () => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'plan', toggle: true}));
    const {
      data: {data}
    } = await BillingAPI.getCurrentSubscription();

    dispatch(setCurrentSubscription(data));

    return data;
  } finally {
    dispatch(toggleFetching({key: 'plan', toggle: false}));
  }
};

export const fetchFeatures = () => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'features', toggle: true}));

    const {
      data: {data}
    } = await BillingAPI.getFeatures();

    dispatch(setFeatures(data));

    return data;
  } catch (err) {
    if (
      ![unauthorizedStatusCode, unprocessableEntityStatusCode, serverErrorStatusCode].includes(
        err.response.status
      )
    ) {
      toast.error('There was an error trying to fetch billing plans');
    }
  } finally {
    dispatch(toggleFetching({key: 'features', toggle: false}));
  }
};

export const fetchInvoices = (loadMore) => async (dispatch, getState) => {
  try {
    dispatch(toggleFetching({key: 'invoices', toggle: true}));

    const lastInvoice = last(getInvoicesSelector(getState()).data);

    const {
      data: {data, meta}
    } = await BillingAPI.getInvoices({
      ...(loadMore && {starting_after: get(lastInvoice, 'id')})
    });

    if (loadMore) {
      dispatch(addInvoices({data, meta}));
    } else {
      dispatch(setInvoices({data, meta}));
    }

    return data;
  } catch (err) {
    if (
      ![unauthorizedStatusCode, unprocessableEntityStatusCode, serverErrorStatusCode].includes(
        err.response.status
      )
    ) {
      toast.error('There was an error trying to fetch invoices');
    }
  } finally {
    dispatch(toggleFetching({key: 'invoices', toggle: false}));
  }
};

export const createInvitation = ({companyId, email}) => async (dispatch, getState) => {
  try {
    await MemberAPI.postCreateInvitation({companyId, email});

    dispatch(fetchInvitations(getCurrentCompanySelector(getState())));
    dispatch(fetchCurrentSubscription());
  } catch (err) {
    const code = err.response.status;

    if (
      ![unauthorizedStatusCode, unprocessableEntityStatusCode, serverErrorStatusCode].includes(code)
    ) {
      toast.error('There was an error trying to Create Invitation');
    } else if (code === unprocessableEntityStatusCode) {
      const emailError = err.response.data.errors.email;

      if (emailError) {
        toast.error(emailError[0]);
      }
    }
  }
};

export const buySeats = ({companyId, seats}) => async (dispatch, getState) => {
  try {
    if (seats > 0) {
      const response = await MemberAPI.postBuySeats({companyId, seats});

      if (response && response.data && response.data.success) {
        toast.success('Additional seats bought.');
      } else {
        throw new Error('Error buying seats', response);
      }
    }

    dispatch(fetchInvitations(getCurrentCompanySelector(getState())));
    dispatch(fetchCurrentSubscription());
    dispatch(fetchInvoices());
  } catch (err) {
    toast.error('There was an error trying to buy seats. Please contact support.');
  }
};

export const deleteSeats = ({companyId, seats}) => async (dispatch, getState) => {
  try {
    if (seats > 0) {
      const response = await MemberAPI.postReduceSeats({companyId, seats});

      if (response && response.data && response.data.success) {
        toast.success('Seats reduced.');
      } else {
        throw new Error('Error reducing seats', response);
      }
    }

    dispatch(fetchInvitations(getCurrentCompanySelector(getState())));
    dispatch(fetchCurrentSubscription());
    dispatch(fetchInvoices());
  } catch (err) {
    toast.error('There was an error trying to reduce seats. Please contact support.');
  }
};

export const removeInvitation = (inviteId) => async (dispatch, getState) => {
  try {
    await MemberAPI.getInvitationRemoved({inviteId});
    dispatch(fetchInvitations(getCurrentCompanySelector(getState())));
    dispatch(fetchCurrentSubscription());
  } catch (err) {
    const code = err.response.status;

    if (
      ![unauthorizedStatusCode, unprocessableEntityStatusCode, serverErrorStatusCode].includes(code)
    ) {
      toast.error('There was an error trying to Remove Invitation');
    }
  }
};

export const fetchInvitations = (companyId) => async (dispatch) => {
  try {
    const {data} = await MemberAPI.getInvitations({companyId});

    const invitations = data.data.invites;

    // This removes owner from the list of members
    // const members = data.data.users.filter((user) => user.email !== profile.email);
    const members = data.data.users;

    const {canManage} = data.data;

    dispatch(setInvitations(invitations));
    dispatch(setTeamMembers(members));
    dispatch(setManageRights(canManage));
  } catch (err) {
    const code = err.response.status;

    if (
      ![unauthorizedStatusCode, unprocessableEntityStatusCode, serverErrorStatusCode].includes(code)
    ) {
      toast.error('There was an error trying to fetch Invitations list');
    }
  }
};

export const fetchUsers = (companyId) => async (dispatch) => {
  try {
    dispatch(toggleFetching({key: 'users', toggle: true}));

    const {data} = await MemberAPI.getCompanyUsers({companyId});

    dispatch(setUsers(data.data));
  } catch (err) {
    const code = err.response.status;

    if (
      ![unauthorizedStatusCode, unprocessableEntityStatusCode, serverErrorStatusCode].includes(code)
    ) {
      toast.error('There was an error trying to fetch users list');
    }
  } finally {
    dispatch(toggleFetching({key: 'users', toggle: false}));
  }
};

export const acceptInvitation = ({token, isAuthenticated}) => async (dispatch) => {
  try {
    await MemberAPI.acceptInvitation({token});
    toast.success('You have successfully joined team');

    if (isAuthenticated) {
      await dispatch(fetchUserProfile());
      await dispatch(fetchCurrentSubscription());
    }
  } catch (err) {
    toast.error('There was an error trying to accept invitation');
  }
};

export const removeTeamMember = ({userId, companyId}) => async (dispatch, getState) => {
  try {
    await MemberAPI.removeTeamMember({userId, companyId});
    dispatch(fetchInvitations(getCurrentCompanySelector(getState())));
    dispatch(fetchCurrentSubscription());
  } catch (err) {
    toast.error('Could not remove member from team. Please contact support.');
  }
};

export const getPlansSelector = createSelector(
  (state) => get(state, 'billing.plans'),
  (plans) => plans
);

export const getFeaturesSelector = createSelector(
  (state) => get(state, 'billing.features'),
  (features) => features
);

export const getUsersSelector = createSelector(
  (state) => get(state, 'billing.users'),
  (users) => users
);

export const getPlanSelector = createSelector(
  (state) => get(state, 'billing.plan'),
  (plan) => plan
);

export const getIsCancelingSelector = createSelector(
  (state) => get(state, 'billing.isCanceling'),
  (isCanceling) => isCanceling
);

export const getIsSwappingSelector = createSelector(
  (state) => get(state, 'billing.isSwapping'),
  (isSwapping) => isSwapping
);

export const getCurrentSubscriptionSelector = createSelector(
  (state) => get(state, 'billing.currentSubscription'),
  (currentSubscription) => currentSubscription
);

const invoiceSlicePart = 1;

export const getOlderInvoicesSelector = createSelector(
  (state) => get(state, 'billing.invoices.data', []),
  (invoices) => invoices.slice(invoiceSlicePart)
);

export const getInvoicesSelector = createSelector(
  (state) => get(state, 'billing.invoices'),
  (invoices) => invoices
);

export const hasMoreInvoicesSelector = createSelector(
  (state) => get(state, 'billing.invoices.meta.has_more', false),
  (hasMoreInvoices) => hasMoreInvoices
);

export const getLatestInvoiceSelector = createSelector(
  (state) => first(get(state, 'billing.invoices.data')),
  (latestInvoice) => latestInvoice
);

export const getCurrentCompanySelector = createSelector(
  (state) => get(state, 'settings.profile.data.companies.meta.currentCompany.id'),
  (companyId) => companyId
);

export const getInvitationsSelector = createSelector(
  (state) => get(state, 'billing.invitations', []),
  (invitations) => invitations
);

export const getTeamMembersSelector = createSelector(
  (state) => get(state, 'billing.teamMembers', []),
  (members) => members
);

export const getCanManageTeamSelector = createSelector(
  (state) => get(state, 'billing.canManage', false),
  (canManage) => canManage
);
