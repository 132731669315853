import {LOCAL_STORAGE_KEYS} from '../../constants/localStorage';

export const clearLocalStorage = () => {
  const savedValues = {};

  Object.values(LOCAL_STORAGE_KEYS).forEach((storageKey) => {
    const value = localStorage.getItem(storageKey);

    if (value) {
      savedValues[storageKey] = value;
    }
  });

  localStorage.clear();

  Object.entries(savedValues).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};

// Utility function to generate a unique transaction ID
const generateTransactionId = ({profileId}) => {
  return `txn_${profileId}_${Date.now()}`;
};

// Store the transaction ID in localStorage
export const storeTransactionId = ({profileId}) => {
  const transactionId = generateTransactionId({profileId});

  localStorage.setItem('purchase_id', transactionId);

  return transactionId;
};

// Retrieve the transaction ID from localStorage
export const getTransactionId = () => {
  return localStorage.getItem('purchase_id');
};
