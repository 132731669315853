import axios from '../../helpers/axios';

export default {
  generateRecommendations: ({
    analysisId, category, role, focus
  }) =>
    axios.post(`/api/studies/generative/${analysisId}/recommendations`, {
      category,
      role,
      focus
    }),
  generateAoiRecommendations: ({aoiId, analysisId}) =>
    axios.post(`/api/studies/generative/${analysisId}/aoi/${aoiId}/recommendations`),
  getRecommendation: ({generativeId}) =>
    axios.get(`/api/studies/generative/${generativeId}/recommendations`)
};
